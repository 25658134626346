.wrapper {
  width: 90%;
  margin: 0 auto;
  .up {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
  }

  .content {
    margin-top: 30px;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .value {
        font-size: 43px;
        font-weight: 600;
      }
      .recipient {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span {
          color: #868688;
        }
        p {
          color: #000;
        }
      }
    }

    .variants {
      width: 100%;
      margin-bottom: 20px;
      justify-content: center;
      .paySvg {
        width: 100%;
        min-width: 100%;
        cursor: pointer;
        svg {
          width: 100%;
        }
      }

      .or {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: #868688;
        margin: 20px 0;

        div {
          flex: 1;
          border: 2px solid #cccbcf;
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}
