.up {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    padding: 50px;
  }
}