.wrapper {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 10%;
  .up {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
  }

  .content {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: 10px;
      font-size: 28px;
      font-weight: 600;
      width: 65%;
      text-align: center;
    }

    span {
      font-size: 15px;
      color: #868688;
      margin-top: 10px;
      width: 65%;
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 30px;
  }
}
