.wrapper {
  width: 90%;
  margin: 0 auto;
  .qrpayBlock{
    width: 100%;
    padding-top: 5%;
    text-align: center;
    padding: 0% 5%;
    .mobileInfo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5% 0%;
      h3{
        font-size: 30px;
      }
      div{
        display: flex;
        flex-direction: column;
        text-align: right;
        p{
          color: #868688;
        }
      }
    }
    .or {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      color: #868688;
      margin: 20px 0;
        div {
          flex: 1;
          border: 2px solid #cccbcf;
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }
  }
  .up {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    .timer {
      background-color: white;
      border-radius: 100px;
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .content {
    margin-top: 30px;
    .buttons {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding-bottom: 20px;
        .qrNavBtn{
          display: flex;
          justify-content: center;
          cursor: pointer;
        }
      }
    .info {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      
      .value {
        width: 80%;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .recipient {
        font-size: 16px;
        width: 80%;

        p {
          color: #000;
          span {
            color: #868688 !important;
          }
        }
      }
    }

    .variants {
      width: 100%;
      margin-bottom: 20px;
      .paySvg {
        width: 100%;
        min-width: 100%;
        cursor: pointer;
        svg {
          width: 100%;
        }
      }

      .or {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: #868688;
        margin: 20px 0;

        div {
          flex: 1;
          border: 2px solid #cccbcf;
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }
    }
  }
}
