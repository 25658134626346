@import "./reset.scss";

@font-face {
    font-family: 'IBMPlexSans';
    src: url(/fonts/IBMPlexSans-SemiBold.ttf);
}
@font-face {
    font-family: 'Manrope-Semibold';
    src: url(/fonts/Manrope-SemiBold.ttf);
}
@font-face {
    font-family: 'Manrope-Bold';
    src: url(/fonts/Manrope-Bold.ttf);
}

.app{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #CECDD5;
    height: 100%;
    min-height: 100vh;
}
.app_pc{
    align-items: center;
}

.loaderBlock{
    padding-bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}