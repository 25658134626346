.container{
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  .btnBlock{
    display: flex;
    align-items: center;
    background-color: #F2F1F3;
    border-radius: 24px;
    width: 372px;
    height: 60px;
    justify-content: center;
    span{
      font-weight: 600;
      display: inline-block;
      margin-left: 3%;
    }
  }
}