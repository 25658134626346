.modalWrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(72, 71, 76, .8);
    overflow: hidden;
    animation: fade-in 0.15s forwards;
    .modalContent{
        width: 350px;
        min-height: 150px;
        margin: 0 auto;
        position: relative;
        z-index: 15;
        border-radius: 34px;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(242, 241, 243, 1);
        .modalHeader{
            border-radius: 34px 34px 0 0;
            background: rgba(255, 255, 255, 1);
            .modalHeaderContent{
                display: flex;
                align-items: center;
                gap: 10px;
                width: 90%;
                margin: 0 auto;
                padding: 20px 0 16px 0;
                p{
                    font-family: 'IBMPlexSans';
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
        .modalBody{
            width: 85%;
            margin: 0 auto;
            padding: 5px 0 20px 0;
            p{
                padding: 16px 0 20px 0;
                color: rgba(0, 0, 0, 0.4);
                font-size: 15px;
                font-family: 'Manrope-Semibold';
                font-weight: 600;

            }
        }
    }
}

.modalImage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all .5s ease;
    .modalImageContent{
        width: 500px;
        max-height: 100vh;
        position: absolute;
        bottom: 0;
        left: 50%;
        background: white;
        border-radius: 20px 20px 0 0;
        transform: translateX(-50%) translateY(100%);
        animation: slide-up 0.3s forwards;
        .closeModalImage{
            position: absolute;
            top: -10px;
            right: 20px;
            cursor: pointer;
            svg:hover{
                path:first-child{
                    fill: #E5E621;
                }
                path:nth-child(2){
                    fill: #589D00;
                }
            }
            svg:active{
                path:first-child{
                    fill: #F8F956;
                }
                path:nth-child(2){
                    fill: #589D00;
                }
            }
        }
        img{
            border-radius: 20px 20px 0 0;
            width: 100%;
            height: 100%;
        }
    }
    @keyframes slide-up {
        from {
          transform: translateX(-50%) translateY(100%);
        }
        to {
          transform: translateX(-50%) translateY(0%);
        }
    }
}
.modalImage_pc{
    .modalImageContent{
        min-height: 700px;
        top: 50%;
        border-radius: 20px;
        animation: slide-up-pc 0.3s forwards;
        img{
            border: 6px solid black;
            border-radius: 20px;
        }
    }
    @keyframes slide-up-pc {
        from {
          transform: translateX(-50%) translateY(100%);
        }
        to {
          transform: translateX(-50%) translateY(-50%);
        }
    }
}

.fadeOut{
    animation: fade-out 0.15s forwards;
}

@keyframes fade-in{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out{
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}