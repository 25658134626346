.container{
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  .btnBlock{
    display: flex;
    align-items: center;
    background-color: #F2F1F3;
    border-radius: 24px;
    width: 372px;
    height: 60px;
    justify-content: center;
    .logo{
      gap: 5px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      span{
        font-weight: 600;
        font-size: 20px;
        display: inline-block;
      }
    }
  }
}