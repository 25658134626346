.payBlock {
  border-radius: 34px;
  background-color: #f2f1f3;
  width: 100%;
  margin-bottom: 20px;
  .head {
    width: 100%;
    background-color: #fff;
    height: 70px;
    border-radius: 34px 34px 0 0;

    .cont {
      width: 90%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        gap: 10px;

        display: flex;
        align-items: center;
        .logo {
          width: fit-content;
          height: 34px;
        }
        .name {
          display: flex;
          flex-direction: column;

          span {
            color: #868688;
            font-size: 14px;
          }
          p {
            color: #000;
            font-size: 16px;
          }
        }
      }
    }
  }
  .body {
    height: 148px;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          color: #868688;
          font-size: 14px;
        }
        p {
          color: #000;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
