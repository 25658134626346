.wrapper {
  width: 90%;
  margin: 0 auto;
  .up {
    display: flex;
    justify-content: space-between;

    margin-top: 10px;
    align-items: center;
    .timer {
      width: 100px;
      background-color: white;
      border-radius: 100px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      span{
        width: 50px;
      }
    }
  }

  .content {
    margin-top: 30px;
    padding-bottom: 10px;
    .info {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      .value {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        p {
          font-size: 32px;
          font-weight: 600;
        }
      }
      .desc {
        font-size: 26px;
        color: #333;
      }
    }

    .variants {
      width: 100%;
      margin-bottom: 20px;
      .paySvg {
        width: 100%;
        min-width: 100%;
        cursor: pointer;
        svg {
          width: 100%;
        }
      }
    }

    .qrContainer {
      width: 100%;
      background-color: #f2f1f3;
      border-radius: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding: 30px 0px ;
      margin-bottom: 20px;

      .qrBg {
        background-color: #fff;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 19px;
      }
      .searchTitle{
        font-weight: 500;
        font-size: 22px;
        color: #333;
        padding-bottom: 5px;
      }
      
      .searchText{
        font-size: 16px;
        color: #868688;
        opacity: .6;
      }
      
    }

    .buttons {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding-bottom: 20px;
      .payBtn {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.or {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #868688;
  margin: 20px 0;

  div {
    flex: 1;
    border: 2px solid #cccbcf;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}
