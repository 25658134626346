.wrapper {
  width: 500px;
  max-width: 500px;
    .container {
      background-color: #e0dfe3;
      height: fit-content;
      border-radius: 0 0 45px 45px;
      .header {
        display: flex;
        font-size: 14px;
        text-align: center;
        padding: 25px 0 15px 0;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        gap: 5px;
        span{
          color: #868688;

        }
        p{
          color: #000;
        }
      }
      .copied {
        background-color: #7752de;
        width: 100%;
        padding: 20px 0 15px 0;
        margin-bottom: 5px;
        border-radius: 0 0 20px 20px;
        text-align: center;
        color: white;
        font-size: 14px;
        animation: fade-in .5s forwards;
      }
      
      .progressBar {
        position: relative;
        height: 5px;
        background-color: #A2A0A9;
        margin-bottom: 20px;
      }
      
      .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #EFF02A;
        transition: width 0.3s ease; 
      }
    }

    .footer {
      margin-top: 40px;
      .payments {
        display: flex;
        justify-content: space-between;
        width: 150px;
        margin: 0 auto;
        align-items: center;
      }

      .options {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        .lang {
          margin-right: 20px;
        }
        .labels {
          display: flex;
          align-items: center;
          gap: 15px;
          p {
            color: #80778f;
            font-size: 10px;
          }
        }
      }
    }
  }

.wrapper_pc{
  padding: 20px 0;
  .container{
    border-radius: 45px;
  }
}


@keyframes fade-in{
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fade-out{
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}