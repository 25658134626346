.hashCont {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    .arrow {
      padding: 10px;
      background-color: #c9c8cc;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
     

    }
    .active{
      background-color: #F8F956;
    }
    .hash {
      color: #000;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 600;
    }
    .tooltip {
      background-color: #000;
      position: absolute;
      top: 35px;
      left: -15px;
      text-wrap: nowrap;
      width: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      padding: 15px;
      border-radius: 15px;
      min-width: 360px;


      .info {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        span {
          font-size: 10px;
          color: #fff;
          opacity: 0.5;
        }
        p {
          color: #f8f956;
          font-size: 15px;
        }
      }

      .copy {
      }
    }
  }

  .tooltip::after {
    display: block;
    width: 0;
    content: "";
    border: 7px solid transparent;
    position: absolute;
  }
  
  .bar::after {
    border-bottom-color: #000;
    border-top: 0;
    top: -7px;
    left: calc(50% - 125px);
  }
  