.btnBlock{
  height: 60px;
  width: 372px;
  border-radius: 25px;
  background-color: rgb(242, 241, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}